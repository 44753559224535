import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import Img from 'gatsby-image'
import Section from 'src/components/Section'
import Development from 'src/components/Development'
import HeadingWysiwyg from 'src/sections/HeadingWysiwyg'
import styled, { createGlobalStyle } from 'styled-components'
import { breakpoint } from 'src/styles/mixins'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.color.lightblue};
  }
`

const Heading = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
`

const Seperator = styled.div`
  width: 100%;
  height: 3px;
  background: #e0e2e9;
  margin: 20px 0;

  ${breakpoint.lg`
    margin: 40px 0;
  `}
`

export const DevelopmentFieldTemplate = ({ acf }) => {
  const intro = acf.developmentFieldSingleIntro
  const { heading, statusRows } = acf.developmentFieldSingleDevelopmentStatus

  const statusRowsMapped = statusRows.map((devField) => (
    <Development
      key={uuidv4()}
      location={devField.location}
      progress={devField.progress}
      titleStart={devField.progressBarTitleStart}
      titleMiddle={devField.progressBarTitleMiddle}
      titleEnd={devField.progressBarTitleEnd}
      statusTitle={devField.statusTitle}
      statusText={devField.statusText}
    />
  ))

  return (
    <>
      <GlobalStyle />
      <HeadingWysiwyg
        preHeading={intro.preHeading}
        heading={intro.heading}
        textWysiwyg={intro.textWysiwyg}
        beforeText={(<Img fluid={intro.image.localFile.childImageSharp.fluid} />)}
      />
      <Section hasPaddingLarge paddingValuesLarge={{ xs: '0 0 96px 0', lg: '0 0 148px 0' }}>
        <Heading>
          {heading}
        </Heading>
        <Seperator />
        {statusRowsMapped}
      </Section>
    </>
  )
}

DevelopmentFieldTemplate.propTypes = {
  acf: PropTypes.shape({
    developmentFieldSingleIntro: PropTypes.shape({
      preHeading: PropTypes.string,
      heading: PropTypes.string,
      textWysiwyg: PropTypes.string,
      image: PropTypes.object, // eslint-disable-line
    }),

    developmentFieldSingleDevelopmentStatus: PropTypes.shape({
      heading: PropTypes.string,
      statusRows: PropTypes.arrayOf(
        PropTypes.shape({
          location: PropTypes.string,
          progress: PropTypes.number,
          progressBarTitleStart: PropTypes.string,
          progressBarTitleMiddle: PropTypes.string,
          progressBarTitleEnd: PropTypes.string,
          statusText: PropTypes.string,
          statusTitle: PropTypes.string,
        })
      ),
    }),
  }).isRequired,
}

export default DevelopmentFieldTemplate
