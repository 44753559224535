import React from 'react'
import styled from 'styled-components'
import { TextBoldStyle } from 'src/styles/typography'
import { breakpoint } from 'src/styles/mixins'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: fit-content;
`

// Do not pass color to the dom element
// eslint-disable-next-line
export const Heading = styled(({ color, ...rest }) => <h2 {...rest} />)`
  ${TextBoldStyle}
  display: inline-block;
  color: ${props => props.color};
  margin: 0 0 8px 0;
  font-size: 0.875rem;
  line-height: 1.2;

  ${breakpoint.lg`
    margin: 0;
    font-size: ${props => props.theme.fontSize.text};
  `}
`

export const Bar = styled.span`
  background-color: ${props => props.theme.color.green};
  width: 60px;
  height: 2px;
  border-radius: 5px;
  margin-right: 8px;
  margin-top: -6px;
  position: absolute;
  display: block;

  ${breakpoint.lg`
    width: 60px;
    margin-top: 0.5rem;
    display: inline-block;
    position: relative;
  `}
`
