import React from 'react'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'
import { getThemeVar } from 'src/styles/mixins'

import { Wrapper, Heading, Bar } from './style'

const HeadingWithBar = ({ children, color }) => {
  return (
    <Wrapper>
      <Bar />
      <Heading color={color}>{ReactHtmlParser(children)}</Heading>
    </Wrapper>
  )
}

HeadingWithBar.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

HeadingWithBar.defaultProps = {
  color: getThemeVar('color.black'),
}

export default HeadingWithBar
