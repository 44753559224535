import React from 'react'
import PropTypes from 'prop-types'
import HeadingWithBar from 'src/components/HeadingWithBar'
import Heading from 'src/components/Heading'
import { getThemeVar } from 'src/styles/mixins'

const Heading2Line = ({ preHeading, heading, color }) => (
  <>
    <HeadingWithBar color={color}>{preHeading}</HeadingWithBar>
    <Heading color={color}>{heading}</Heading>
  </>
)

Heading2Line.propTypes = {
  preHeading: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

Heading2Line.defaultProps = {
  color: getThemeVar('color.black'),
}

export default Heading2Line
