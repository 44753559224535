import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core/'

import {
  Hr,
  ProgressBar,
  ProgressLabels,
  RightColumn,
  CountryLabel,
  MyBold,
  StatusText
} from './style'

const Development = ({ location, progress, titleStart, titleMiddle, titleEnd, statusTitle, statusText }) => (
  <>
    <Grid
      container
      justify="space-between"
      spacing={1}
    >
      <Grid
        container
        direction="column"
        justify="center"
        xs={12}
        md={2}
      >
        <CountryLabel>
          {location}
        </CountryLabel>
      </Grid>
      <Grid
        container
        direction="column"
        justify="center"
        xs={12}
        md={6}
      >
        <ProgressLabels>
          <MyBold>{titleStart}</MyBold>
          <MyBold>{titleMiddle}</MyBold>
          <MyBold>{titleEnd}</MyBold>
        </ProgressLabels>
        <ProgressBar percent={progress} />
      </Grid>
      <Grid xs={12} md={2}>
        <RightColumn>
          <ProgressLabels>
            <MyBold>{statusTitle}</MyBold>
          </ProgressLabels>
          <StatusText>
            <div>{statusText}</div>
          </StatusText>
        </RightColumn>
      </Grid>
    </Grid>
    <Hr />
  </>
)

Development.propTypes = {
  progress: PropTypes.number.isRequired,
  location: PropTypes.string.isRequired,
  titleStart: PropTypes.string.isRequired,
  titleMiddle: PropTypes.string.isRequired,
  titleEnd: PropTypes.string.isRequired,
  statusTitle: PropTypes.string.isRequired,
  statusText: PropTypes.string.isRequired,
}

export default Development
