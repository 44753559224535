import React from 'react'
import styled from 'styled-components'
import { TextBoldStyle, TextBold } from 'src/styles/typography'
import { breakpoint } from 'src/styles/mixins'


export const CountryLabel = styled.div`
  ${TextBoldStyle}
  font-size: 40px;
  position: relative;
  padding: 0 0 0 17px;
  ${breakpoint.lg`
    font-size: 50px;
    position: relative;
  `}
  &::before{
    content: '';
    position: absolute;
    height: 40px;
    width: 4px;
    display: inline;
    background: ${({ theme }) => theme.color.green};
    left: 0;
    top: 0.9rem;
  }
`

export const RightColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  ${breakpoint.lg`
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  `}
`

const OuterFill = styled.div`
  width: 100%;
  background-color: #dcdee7;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
`

const InnerFill = styled.div`
  position: relative;
  height: 15px;
  width: ${({ percent }) => `${percent}%`};
  background: ${({ theme }) => theme.color.green};
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
`

const StyledProgress = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

// eslint-disable-next-line
export const ProgressBar = ({percent}) => (
  <StyledProgress>
    <OuterFill>
      <InnerFill percent={percent} />
    </OuterFill>
  </StyledProgress>
)

export const ProgressLabels = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 15px 0;
`

export const StatusText = styled(ProgressLabels)`
    justify-content: end;
    font-weight: normal;
  ${breakpoint.lg`
    justify-content: space-between;
  `}
`

export const MyBold = styled(TextBold)`
  font-size: ${({ fontSize }) => fontSize || 1}em;
  color: black;
  margin: 0;
  padding: ${({ hasPadding }) => hasPadding && '50px 0'};
`

export const Hr = styled.div`
  width: 100%;
  height: 3px;
  background: #e0e2e9;
  margin: 20px 0;
  ${breakpoint.lg`
    margin: 40px 0;
  `}
`
